<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',

}


</script>

<style lang="scss">

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: auto;
  height: auto;
  max-width: 560px;
  margin: 0 auto;
  background: url("../public/assets/background.png") repeat center center;
}

@media (min-width: 576px) {
  #app {
    width:576px;
    aspect-ratio: 1.5;
    height: 100%;
  }
}
</style>
