<template>
   <div class="menu">
     <RouterLink class="item" v-for="(menu, index) in menus" :to="`/${activeLanguage}/${menu.uid}`" active-class="active" :key="index" onclick="$emit('scrollToItem', menu.id)">
       <span>{{ menu.label}}</span>
     </RouterLink>
   </div>
</template>

<script>
export default {
  name: "MenuNavigation",
  props: {
    activeLanguage: {
      type: String,
      required: true,
    },
    activeType: {
      type: String,
      required: true,
    }
  },
  computed: {
    menus() {
      if(this.activeLanguage === 'ro') {
        return [
          {
            uid: 'food',
            label: 'Meniu Mancare',
          },
          {
            uid: 'drink',
            label: 'Meniu Bautura',
          },
        ];

      }
      return [
        {
          uid: 'food',
          label: 'Food Menu',
        },
        {
          uid: 'drink',
          label: 'Drinks Menu',
        },
      ];
    },
  }
}
</script>

<style lang="scss" scoped>
.menu {
  width: 100%;
  position: fixed;
  padding-top: 20px;
  padding-bottom: 12px;
  top: 0;
  background: url("../../public/assets/top-background.png") no-repeat center top;
  z-index: 2;

  .item {
    line-height: 28px;
    padding: 12px;
    margin: 0 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #3A4841;
    text-decoration: none;

    &.active {
      background: #3A4841;
      border-radius: 40px;
      color: #ffffff;
    }
  }
}
</style>
