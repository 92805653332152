<template>
  <div class="category-navigation">
    <router-link class="link" v-for="(category, index) in items" :key="index" :to="`#${category.id}`" active-class="active"> {{ category.name }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'MenuCategoryComponent',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  mounted() {
    document.addEventListener("scroll", function () {
      const navbar = document.querySelector(".navbar");
      const navbarHeight = 100;
      const distanceFromTop = Math.abs(
          document.body.getBoundingClientRect().top
      );
      if (distanceFromTop >= navbarHeight) navbar.classList.add("fixed-top");
      else navbar.classList.remove("fixed-top");
    });
  }
}
</script>

<style lang="scss">
.category-navigation {
  background: #575855;
  font-family: 'Roboto', serif;
  overflow-y: auto;
  white-space: nowrap;
  max-height: 40px;
  padding: 7px 0 7px 8px;

  .link {
    display: inline-block;
    line-height: 24px;
    height: 24px;
    padding: 0 8px;
    border: 1px solid #FFFFFF;
    border-radius: 40px;
    color: #ffffff;
    margin: 0 4px;
    text-decoration: none;
    font-weight: 400;

    &.active {
      background: #90D0AE;
      color: #3A4841;
      border: 1px solid #3A4841;
    }
  }
}
</style>
