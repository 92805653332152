<template>
  <div class="category" :id="item.id">
    <div class="headline">
      <img :src="item.imageUrl" :alt="item.name">
      <h2>{{ item.name }}</h2>
    </div>
    <div class="products">
      <div class="product" v-for="item in products" :key="item.id">
        <p class="product__title">{{item.name}} - {{`${item.quantity}${item.quantityUnit}`}}</p>
        <span class="spacer"/>
        <p class="product__price">{{`${item.price} ${item.currency}`}}</p>
        <p class="product__description">{{item.description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Category',
  props: {
   item: {
     type: Object,
     required: true,
   }
  },
  computed: {
    products: function () {
      return this.item.products;
    }
  }
}
</script>


<style lang="scss">
.category {
   margin: 18px 0;
  .headline {
    position: relative;
    padding: 18px 0;

    img {
      width: 45%;
      margin: 0 auto;
    }

    h2 {
      font-family: 'LuckoftheIrish', serif;
      font-style: normal;
      font-size: 32px;
      line-height: 40px;
      padding: 0;
      margin: -15% 0 0 0;
    }
  }
  .products {
    text-align: left;
   .product {
     padding: 12px;
     font-family: 'MrsEaves';
     font-style: italic;

     .product__title {
       font-style: italic;
       font-weight: bold;
       font-size: 29px;
       line-height: 32px;
       padding: 8px 0;
       margin: 0;
     }
     .spacer {
       display: block;
       height: 1px;
       background: #000000;
       width: 25%;
     }
     .product__price {
       font-weight: 500;
       font-size: 25px;
       line-height: 23px;
       margin: 0;
       padding: 8px 0;
     }

     .product__description {
       margin: 0;
       padding-top: 4px;
       font-weight: bold;
       font-size: 16px;
       line-height: 20px;
     }
   }


  }

}
</style>
